// import store from '@/store';
// import moment from "moment";
import consts from "@/consts";
import {getMainParentRouteName} from "@/lib/lib";

const defaultSetRoute = {
    md: (route, router, groupId) => {
        if(getMainParentRouteName(route.meta?.mainRouteName) !== consts.routerNames.md.main) {
            if(this.$isDevelopment) console.warn(consts.routerNames.md.main+' returnToParent', route);
            return false;
        }
        router.push({
            name: consts.routerNames.md.main,
            params: {
                groupId
            },
            query: {...route.query}
        })
    },
    unitsWidget: (route, router, widget) => {
        if(getMainParentRouteName(route.meta?.mainRouteName) !== consts.routerNames.md.main) {
            if(this.$isDevelopment) console.warn(consts.routerNames.md.main+' returnToParent', route);
            return false;
        }
        let groupId = route.params.groupId
        router.push({
            name: consts.routerNames.md.widget,
            params: {
                widget,
                groupId,
            },
            query: {...route.query}
        })
    },
}

const routes = (process.env.VUE_APP_PACKAGE !== 'prometheus.portal') ? [] : [
    {
        path: 'md/:groupId?',
        name: consts.routerNames.md.main,
        meta: {
            mainRouteName: consts.routerNames.md.main,
            pageClass: 'unit-md-page',
            layers: consts.defaultSettingsLayers,
            setRoute: defaultSetRoute,
        },
        component: () => import('@/views/prometheus/portal/MdPage.vue'),
        children: [
            {
                path: ':widget',
                name: consts.routerNames.md.widget,
                meta:{
                    pageClass: 'unit-md-page unit-md-page__full',
                    mainRouteName: consts.routerNames.md.main,
                    parentRouteName: consts.routerNames.md.main,
                },
            }
        ]
    },
];

export default routes;